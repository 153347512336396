<template>
  <layout :loading="loading">
    <h2 class="mb-2">Console Commands</h2>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="attendance:check"
        >
          <b-card-text>Check exist previous day attendances</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('attendance:check')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="birthdays:check"
        >
          <b-card-text>Sending notifications about employee birthdays that occur in the next 3 days</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('birthdays:check')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="calendar:annual"
        >
          <b-card-text>Create calendar for year</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('calendar:annual')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="contracts:check"
        >
          <b-card-text>Send notification to HR manager if there are expiring contracts</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('contracts:check')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="currency-rates:import"
        >
          <b-card-text>Import currency rates</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('currency-rates:import')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="dismissal-warnings:check"
        >
          <b-card-text>Send Slack notification if Dismissal Warning decision date approaches</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('dismissal-warnings:check')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="employee-status:check"
        >
          <b-card-text>Update employee`s status</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('employee-status:check')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="erp:post"
        >
          <b-card-text>ERP Analytics posting</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('erp:post')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:taking-lunch-in-office"
        >
          <b-card-text>Notify employee taking lunch in remote</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:taking-lunch-in-office')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="notify:working-in-office"
        >
          <b-card-text>Notify employee working in office</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:working-in-office')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:on-time-check-in-warning --location_id=1"
        >
          <b-card-text>Send Slack notification if one has not checked in (office)</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:on-time-check-in-warning --location_id=1')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:on-time-check-in-warning --location_id=4"
        >
          <b-card-text>Send Slack notification if one has not checked in (remote)</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:on-time-check-in-warning --location_id=4')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="notify:feedback"
        >
          <b-card-text>Send Feedback notification to slack and mail</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:feedback')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="send-to-openai:attendances"
        >
          <b-card-text>Send worksheet to OpenAI</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('send-to-openai:attendances')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="soa:sync"
        >
          <b-card-text>SOA database tables synchronization</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('soa:sync')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
  </layout>
</template>
<script>
export default {
  name: 'Commands',
  data() {
    return {
      loading: false
    }
  },

  methods:{
    async sendConsoleCommand(command){
      this.loading = true
      await this.$store.dispatch('settings/sendConsoleCommand', {'command':command})
      this.loading = false
    }
  },
}
</script>